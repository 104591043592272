@import "../../assets/scss/index.scss";

@include respond("md") {
  .text {
    font-size: 14px;
    line-height: 1.71em;

    &._type-2 {
      font-size: 14px;
      line-height: 1.71em;
    }

    & p,
    & ul,
    & ol {
      margin: 24px 0;
    }

    & li {
    }

    // TITLES

    & h1,
    & h2,
    & h3,
    & h4,
    & h5,
    & h6 {
      margin: 24px 0;
    }

    & h3 {
      font-size: 24px;
    }

    & h4 {
      font-size: 18px;
    }

    // TABLE

    & table {
      margin: 24px 0;
    }

    & th,
    & td {
      min-width: 180px;
      padding: 6px 26px 6px 10px;
    }

    // IMAGE

    & img {
      margin: 40px 0;
    }
  }
}
