@import "../../assets/scss/index.scss";

.u_page_container {
  padding-top: 80px;

  &-lines {
    position: absolute;
    top: 0;
    width: 100%;
    height: 100%;
    background-position: top center;
    background-repeat: no-repeat;
    z-index: -1;

    @include respond(md) {
      background-size: contain;
    }
  }
}
