@import "../../../../assets/scss/index";

.awards_n_recognitions {
  width: 100%;
  background: rgba(235, 241, 251, 0.3);
  border-radius: 20px;
  margin-top: 50px;
  padding: 0 43px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 40px;

  &-header {
    max-width: 230px;
    margin-right: 20px;

    font-family: "Montserrat", sans-serif;
    font-size: 32px;
    font-weight: 800;
    line-height: 56px;
    letter-spacing: 1px;
    color: #252525;
  }

  &-content {
    width: 50%;
    flex-grow: 1;
  }

  &-slide {
    display: flex;
    justify-content: center;
    padding: 40px 15px;

    img {
      position: relative;
      margin: 0 auto;
      height: 100px;
      width: 100%;
      object-fit: contain;
      transition: 0.3s linear all;

      &:hover {
        transform: scale(1.44);
        filter: drop-shadow(0px 2px 10px rgba(0, 0, 0, 0.25));

        @include respond("lg") {
          filter: unset;
          transform: scale(1);
        }
      }
    }
  }

  & .slick-track {
    display: flex;
    align-items: center;
  }

  & .slick-arrow {
    @include carouselArrowBtn;

    &.slick-prev {
      left: -25px;
    }

    &.slick-next {
      right: -25px;
    }
  }

  & .slick-dots {
    @include carouselDots;
  }

  // ADAPTIVE

  @media screen and (max-width: 1200px) {
    padding-left: 32px;
    padding-right: 32px;
  }

  @include respond("lg") {
    flex-direction: column;
    padding-left: 24px;
    padding-right: 24px;

    &-header {
      justify-content: center;
      padding: 0;
      margin: 0 0 10px;
      width: 100%;
      max-width: 100%;

      text-align: center;
    }

    &-content {
      width: 100%;
    }
  }

  @include respond("md") {
    width: auto;
    border-radius: 0;
    padding: 24px $page-padding-md 38px;
    margin-left: -$page-padding-md;
    margin-right: -$page-padding-md;

    &-header {
      margin-bottom: 24px;

      font-size: 28px;
      line-height: 1.3em;
    }

    & .slick-arrow.slick-prev {
      left: -16px;
    }

    & .slick-arrow.slick-next {
      right: -16px;
    }
  }

  @include respond("sm") {
    padding: 24px $page-padding-sm 38px;
    margin-left: -$page-padding-sm;
    margin-right: -$page-padding-sm;

    &-header {
      margin-bottom: 14px;

      font-size: 20px;
    }
  }
}
