@import "../../assets/scss/index";

.case-article {
  display: flex;
  flex-direction: column;
  align-items: flex-start;

  text-decoration: none;
  color: inherit;

  &__img-wrap {
    position: relative;
    width: 100%;
    border-radius: $radius;
    padding-top: 78.12%;
    overflow: hidden;
    margin-bottom: 20px;
    cursor: pointer;
    display: flex;
    background: linear-gradient(315deg, #a6c5f6 0%, #eeeeee 100%);

    text-decoration: none;
    color: inherit;
  }

  &__img-overlay {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 3;
    width: 100%;
    height: 100%;
    background-image: url("../../../src/assets/images/homepage/group-hover.svg");
    background-size: cover;
    display: flex;
    justify-content: center;
    align-items: center;
    opacity: 0;

    transition: $time;

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        opacity: 1;
      }
    }
  }

  &__img-overlay-btn {
  }

  &__img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: contain;

    & img,
    & source {
      width: 100%;
      height: 100%;
      object-fit: contain;
    }
  }

  &__content {
    padding: 0 24px;
  }

  &__title {
    margin: 0 0 12px;

    font-size: 24px;
    line-height: 1.5em;
    font-weight: 600;
    letter-spacing: 0.5px;

    & a {
      text-decoration: none;
      color: inherit;

      transition: $time;
    }

    @media (hover: hover) and (pointer: fine) {
      & a:hover {
        color: $blue;
      }
    }
  }

  &__text {
    margin-bottom: 18px;
    min-height: 64px;
  }
}
