@import "../../assets/scss/index.scss";

.tags-list {
  overflow: hidden;

  &__list {
    margin: -8px -16px;
    display: flex;
    flex-wrap: wrap;
  }

  &__item {
    position: relative;
    padding: 8px 16px;

    &::before {
      content: "";
      position: absolute;
      left: -1px;
      top: 50%;
      transform: translateY(-50%);
      width: 2px;
      height: 18px;
      background-color: #ebf1fb;
    }
  }

  &__link {
    color: $blue;
    font-size: 14px;
    line-height: 17px;
    font-weight: 600;

    transition: $time;
  }
}

a.tags-list__link {
  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: $hover-blue;
      text-decoration: underline;
    }
  }
}
