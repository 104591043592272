@import "../../assets/scss/index.scss";

.text {
  width: 100%;
  font-size: 18px;
  line-height: 1.77em;
  font-weight: 400;
  color: $blue-100;

  &._type-2 {
    font-size: 16px;
    line-height: 1.55em;
  }

  & > *:first-child {
    margin-top: 0;
  }

  & > *:last-child {
    margin-bottom: 0;
  }

  & a {
    color: $blue;
  }

  @media (hover: hover) and (pointer: fine) {
    & a:hover {
      color: $hover-blue;
    }
  }

  & p,
  & ul,
  & ol {
    margin: 30px 0;
  }

  & li {
    margin-bottom: 6px;

    &:last-child {
      margin-bottom: 0;
    }
  }

  & ul li {
    position: relative;
    padding-left: 18px;

    &::before {
      content: "";
      position: absolute;
      left: 0;
      top: 12px;
      width: 8px;
      height: 8px;
      background-color: currentColor;
      border-radius: 50%;
    }
  }

  & ol {
    counter-reset: item;
  }

  & ol li {
    position: relative;
    padding-left: 26px;

    &::before {
      content: counter(item) ".";
      counter-increment: item;
      position: absolute;
      left: 0;
      top: 0;
    }
  }

  & p + ul,
  & p + ol {
    margin-top: -15px;
  }

  // TITLES

  & h1 {
    margin: 50px 0 30px;

    @include h1;
  }

  & h2 {
    margin: 40px 0 30px;

    @include h2;
  }

  & h3 {
    margin: 40px 0 30px;

    @include h3;
    font-weight: 600;
  }

  & h4 {
    margin: 30px 0;

    @include h4;
    font-weight: 600;
  }

  & h5 {
    margin: 30px 0;

    @include h5;
  }

  & h6 {
    margin: 30px 0;

    @include h6;
  }

  // TABLE

  & table {
    display: block;
    border-collapse: collapse;
    max-width: 100%;
    margin: 30px 0;
    border: 2px solid #c2c4c8;
    border-radius: $radius;
    overflow-x: auto;
  }

  & thead,
  & tbody,
  & tfoot {
    min-width: 600px;
  }

  & th,
  & td {
    min-width: 180px;
    padding: 8px 20px;
    border: 2px solid #c2c4c8;
    border-top: 0;
    border-left: 0;
    border-radius: $radius;

    text-align: left;

    &:last-child {
      border-right: 0;
    }
  }

  & tr:last-child td {
    border-bottom: 0;
  }

  & th {
    font-weight: 800;
  }

  // IMAGES

  & img {
    max-width: 100%;
    margin: 50px 0;
    height: auto;
    width: auto;
    display: block;
    border-radius: 10px;
  }

  // STYLES

  & b,
  & strong {
    font-weight: 800;
  }

  & em,
  & i {
    font-style: italic;
  }

  p,
  li,
  td {
    span {
      font-weight: 400 !important;
      font-size: 18px !important;
      line-height: 32px !important;

      @media (max-width: 450px) {
        font-size: 14px !important;
        line-height: 24px !important;
      }
    }
  }

  h1,
  h2,
  h3 {
    @media (max-width: 450px) {
      line-height: 30px !important;

      span {
        font-size: 24px !important;
        line-height: 30px !important;
      }
    }
  }
}
