.u-preloader {
  position: absolute;
  z-index: 20;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: rgba(white, 0.4);

  &._transparent {
    background: transparent;
  }

  &._inline {
    background: transparent;
    position: static;
    min-height: 100px;
    width: 100%;
  }

  &__loading {
    width: 62px;
    height: 62px;
    border: #2562bd 4px solid;
    border-left-color: transparent;
    border-radius: 50%;
    animation: rotating 1s linear infinite;
  }
}
