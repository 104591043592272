@import "../../assets/scss/index.scss";

.cases-articles-section {
  &__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 32px;
  }

  &__title {
    @include h2;
  }

  &__more {
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    background: $blue;
    min-width: 210px;
    padding: 11px 20px;
    border-radius: $radius-large;

    font-weight: 600;
    font-size: 14px;
    line-height: 18px;
    text-transform: none;
    text-decoration: none;
    color: white;

    transition: $time;

    &._mob {
      display: none;
    }

    & svg {
      width: 12px;
      height: 8px;
      margin-left: 5px;
      transform: rotate(-90deg);
      fill: currentColor;

      transition: $time;
    }

    @media (hover: hover) and (pointer: fine) {
      &:hover {
        background-color: $hover-blue;
      }
    }
  }

  &__list {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;
  }

  &__item {
    width: calc((100% - 60px) / 3);
    margin-right: 30px;
    margin-top: 40px;

    &:nth-child(1),
    &:nth-child(2),
    &:nth-child(3) {
      margin-top: 0;
    }

    &:nth-child(3n) {
      margin-right: 0;
    }
  }
}
